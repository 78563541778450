body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fullImg {
  background-image: url('./fullPage.jpg');
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homeMsgClass {
  width: 65%;
  text-align: left;
}

.contactMsgClass {
  width: 80%;
  text-align: left;
}
